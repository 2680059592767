<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>收款码管理</el-breadcrumb-item>
          <el-breadcrumb-item>收款码批次</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="批次">
              <el-select
                v-model="where.batchNo"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in batchList"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                >条件筛选</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col>
              <el-button type="primary" icon="el-icon-plus" @click="batchDialog"
                >批量生成
              </el-button>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="admin_table_main">
        <div class="admin_table_main">
          <el-table :data="list" @selection-change="handleSelectionChange">
            <el-table-column prop="batchNo" label="批次号"></el-table-column>
            <el-table-column
              prop="payQrcodeNoRange"
              label="编码起-止"
            ></el-table-column>
            <el-table-column prop="qrcodeNum" label="数量"></el-table-column>
            <el-table-column
              prop="createTime"
              label="入库时间"
            ></el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column label="操作" fixed="right" width="100px">
              <template slot-scope="scope">
                <el-dropdown>
                  <span class="el-dropdown-link"
                    >操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <el-button
                        type="text"
                        icon="el-icon-document"
                        size="medium"
                        @click="
                          $router.push({
                            name: 'paymentcodeList',
                            query: { batchNo: scope.row.batchNo },
                          })
                        "
                      >
                        查看
                      </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        type="text"
                        icon="el-icon-download"
                        size="medium"
                        @click="download(scope.row.batchNo)"
                      >
                        下载图
                      </el-button>
                    </el-dropdown-item>
                    <el-dropdown-item>
                      <el-button
                        type="text"
                        icon="el-icon-download"
                        size="medium"
                        @click="downloadExcel(scope.row.batchNo)"
                      >
                        下载Excel
                      </el-button>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>

          <div class="admin_table_main_pagination">
            <el-pagination
              @current-change="currentChange"
              @size-change="handleSizeChange"
              background
              :total="totalData"
              :page-size="pageSize"
              :current-page="currentPage"
              :page-sizes="[10, 20, 50, 100]"
              layout="sizes, prev, pager, next, total, jumper"
            ></el-pagination>
          </div>
        </div>
        <batch
          @dialog="getDialog"
          :qrcodeData="qrcodeData"
          v-if="dialogBatchVisible"
        ></batch>
      </div>
    </div>
  </div>
</template>
<script>
import batch from "@/components/admin/payment_code/batch.vue";

export default {
  components: {
    batch,
  },
  data() {
    return {
      list: [],
      dialogBatchVisible: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      batchList: "",
      where: {
        pageNum: 1,
        pageSize: 0,
        batchNo: "",
      },
      qrcodeData: {},
    };
  },
  methods: {
    listData() {
      this.where.pageSize = this.pageSize;
      this.where.pageNum = this.currentPage;
      this.$post(this.$api.payCodeBatchList, this.where).then((res) => {
        if (res.code == 1) {
          this.total_data = res.data.count;
          this.list = res.data.records;
        }
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v);
      });
      this.selectId = ids;
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    getDialog(val) {
      this.dialogBatchVisible = val;
    },
    batchDialog() {
      this.dialogBatchVisible = true;
    },
    getLatchList() {
      this.$get(this.$api.qryQrcodeBatchNoList, {}).then((res) => {
        if (res.code == 1) {
          this.batchList = res.data;
        }
      });
    },
    download(val) {
      this.$post(this.$api.payCodeDownload, { batchNo: val }).then((res) => {
        if (res.code === 1) {
          location.href = res.data;
        }
      });
    },
    downloadExcel(val) {
      location.href = this.$api.payCodeDownloadExcel + val;
    },
  },
  created() {
    this.listData();
    this.getLatchList();
  },
};
</script>
