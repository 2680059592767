<template>
  <div class="app">
    <el-dialog
      title="批量生成"
      :visible.sync="dialogSendVisible"
      width="20%"
      :before-close="handleSendClose"
    >
      <el-timeline>
        <el-form ref="form" :model="form">
          <el-form-item
            label="数量"
            :label-width="formLabelWidth"
            prop="qrcodeNum"
            :rules="[
              { required: true, message: '请输入正确数据', trigger: 'blur' },
            ]"
          >
            <el-input-number
              v-model="form.qrcodeNum"
              controls-position="right"
              :min="1"
              :max="1000"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            label="备注"
            :label-width="formLabelWidth"
            prop="remark"
            :rules="[
              { required: true, message: '请输入备注', trigger: 'blur' },
              {
                min: 2,
                max: 200,
                message: '长度在 2 到 200 个字符',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              type="textarea"
              v-model="form.remark"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-timeline>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSendClose">取 消</el-button>
        <el-button type="primary" @click="send('form')">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { qrcanvas } from 'qrcanvas';
// import html2canvas from 'html2canvas';
export default {
  data() {
    return {
      dialogSendVisible: true,
      formLabelWidth: "80px",
      form: {
        qrcodeNum: 0,
        remark: "",
        operator: "",
      },
    };
  },
  props: {
    qrcodeData: Object,
  },
  created() {
    let user_info = localStorage.getItem("user_info");
    let user = JSON.parse(user_info);
    this.form.operator = user.nickname || user.id;
  },

  methods: {
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
    send(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.post(this.$api.payCodeBatchAdd, this.form);
              this.handleSendClose();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          this.$parent.listData();
          this.$parent.getLatchList();
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
</style>

